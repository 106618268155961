import * as Sentry from '@sentry/react';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import {
  RouterProvider, useLocation, useNavigationType, matchRoutes, createRoutesFromChildren,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { router } from '@app/AppRouter';
import appConfig from '@app/config/app';
import { LoadingBlock } from '@shared/utils';
import { stringEscape } from '@shared/helpers';
import CacheBuster from '@shared/utils/CacheBuster';

import AppProvider from './AppProvider';
import { store, persistor } from './store';

const urlPropagate = new RegExp(`^${stringEscape(appConfig.apiUrl)}`);

// dont trigger sentry if in local
if (appConfig.env !== 'local') {
  Sentry.init({
    dsn: appConfig.sentryDSN,
    environment: appConfig.env,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [ urlPropagate ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const App = () => (
  <CacheBuster>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<LoadingBlock />}>
          <AppProvider>
            <RouterProvider router={router} />
          </AppProvider>
        </Suspense>
      </PersistGate>
    </Provider>
  </CacheBuster>
);

export default App;
