import {
  generatePostAction, generateGetAction,
  generatePutAction, generateAction,
} from '@services/ActionDispatcher';

import {
  getAllTicketsAction,
  declineTicketAction,
  getUploadedTemplateListAction,
  generateDocumentAction,
  clearGeneratedTemplateAction,
  getTicketAction,
  removeExpiredTicketAction,
  sendTicketPleaDealAction,
  uploadTicketReceiptAction,
} from './TicketsActionConfig';

// action request dispatchers
const getAllTicketRequest = (queryParams) => generateGetAction(
  // action name
  getAllTicketsAction,
  // url
  'lawyer/ticket/pending',
  { queryParams },
);

const declineTicketRequest = (data) => generatePostAction(
  // action name
  declineTicketAction,
  // url
  'lawyer/ticket/decline',
  { ...data },
);

const acceptTicketRequest = (data) => generatePutAction(
  // action name
  declineTicketAction,
  // url
  `lawyer/ticket/accept/${data.ticket_id}`,
  { ...data },
);

const getUploadedTemplateListRequest = (type) => generateGetAction(
  // action name
  getUploadedTemplateListAction,
  // url
  `lawyer/templates/${type}`,
);

const generateDocumentRequest = (data) => generatePostAction(
  // action name
  generateDocumentAction,
  // url
  'lawyer/template/preview',
  { ...data },
);

const clearGeneratedTemplateDispatch = () => generateAction(
  clearGeneratedTemplateAction,
);

const getTicketRequest = (id) => generateGetAction(
  // action name
  getTicketAction,
  // url
  `lawyer/ticket/${id}`,
);

const sendTicketPleaDealRequest = (data) => generatePutAction(
  // action name
  sendTicketPleaDealAction,
  // url
  'lawyer/ticket/send-plea-deal',
  { ...data },
);

const uploadTicketReceiptRequest = (data) => generatePostAction(
  // action name
  uploadTicketReceiptAction,
  // url
  `lawyer/ticket/${data.ticket_id}/receipt`,
  { ...data },
);

const removeExpiredTicketDispatch = (data: any) => generateAction(removeExpiredTicketAction, data);

// register actions here
export {
  // normal dispatch
  clearGeneratedTemplateDispatch,
  removeExpiredTicketDispatch,

  // async (request) dispatch
  declineTicketRequest,
  getAllTicketRequest,
  acceptTicketRequest,
  getUploadedTemplateListRequest,
  generateDocumentRequest,
  getTicketRequest,
  sendTicketPleaDealRequest,
  uploadTicketReceiptRequest,
};
